<template>
  <v-card outlined>
    <v-card-title>
      <div>
        <div v-if="!fromShare" class="font-size-h3 dark-grey-text bold-text">
          Chat
          <v-chip x-small> BETA </v-chip>
        </div>
        <div class="text-muted bold-text text-body-1">
          Collaborate with your team members on app releases and issues
        </div>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="lightGreyBackground">
      <v-row v-on:scroll="getScrollValue" id="messageBox" class="scrollable">
        <v-col cols="12">
          <v-row v-if="loading">
            <v-col v-for="i in 4" :key="i" cols="12">
              <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            </v-col>
          </v-row>
          <div v-if="!loading && getAppMessages.length">
            <v-row v-if="moreData">
              <v-col class="text-center" cols="12">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row
              :justify="currentUser.id === detail.user.id ? 'end' : 'start'"
              v-for="(detail, index) in getAppMessages"
              :key="index"
            >
              <v-col cols="8">
                <v-row
                  align="center"
                  :justify="currentUser.id == detail.user.id ? 'end' : 'start'"
                >
                  <v-col
                    v-if="currentUser.id !== detail.user.id"
                    class="pr-0"
                    cols="auto"
                  >
                    <ProfileImage
                      size="30"
                      :image="detail.user.image"
                      :alt="detail.user.name"
                    />
                  </v-col>
                  <v-col
                    style="float: right"
                    :cols="$vuetify.breakpoint.smAndDown ? '9' : 'auto'"
                  >
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-card
                          flat
                          :class="
                            currentUser.id == detail.user.id
                              ? 'black--text lightBackground'
                              : 'black--text'
                          "
                          :style="
                            currentUser.id == detail.user.id
                              ? 'background-color: rgba(57, 107, 219, 0.05)'
                              : ''
                          "
                          class="pa-2 lightBackground"
                          v-on="currentUser.id == detail.user.id ? on : ''"
                          box
                        >
                          <div>
                            <span class="black--text">
                              {{
                                currentUser.id === detail.user.id
                                  ? ""
                                  : detail.user.name
                              }}
                            </span>
                            <span class="caption">
                              <vue-hoverable-date
                                :date="detail.created_at"
                              ></vue-hoverable-date>
                            </span>
                          </div>
                          <div style="white-space: pre-line">
                            {{ detail.message }}
                          </div>
                        </v-card>
                      </template>
                      <v-list dense>
                        <!--                <v-list-item-->
                        <!--                  class="cursor-pointer"-->
                        <!--                  @click="onEditClick(detail)"-->
                        <!--                >-->
                        <!--                  <v-icon small left>edit</v-icon>Edit-->
                        <!--                </v-list-item>-->
                        <v-list-item
                          class="cursor-pointer"
                          @click="onDeleteClick(detail)"
                        >
                          <v-icon small left>delete</v-icon>Delete
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-card
                      v-if="showEditForm && editContent.id == detail.id"
                      class="pa-2 py-3"
                    >
                      <v-row>
                        <v-col class="pb-0" cols="12">
                          <v-textarea
                            filled
                            auto-grow
                            v-model="editContent.message"
                            rows="2"
                            v-validate="'required'"
                            v-bind="veeValidate('Message', 'Message')"
                          ></v-textarea>
                        </v-col>
                        <v-col cols="12" class="pt-0 text-right">
                          <v-btn
                            class="text-transform-none"
                            text
                            color="primary"
                            :disabled="busy"
                            @click="cancelEdit"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="primary"
                            class="text-transform-none"
                            depressed
                            :loading="busy"
                            @click="editMessage"
                            >Save</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-row
            class="mt-15"
            justify="center"
            align="center"
            v-if="!loading && !getAppMessages.length"
          >
            <v-col class="mt-10" align-self="center" cols="12">
              <no-data
                first-text="No messages"
                second-text="Start the conversation with your team about this app"
              ></no-data>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-3 lightBackground">
      <v-row align="center">
        <v-col class="pr-0" cols="auto">
          <emoji-picker @emoji="insert" :search="search">
            <template
              slot="emoji-invoker"
              slot-scope="{ events: { click: clickEvent } }"
            >
              <div @click.stop="clickEvent">
                <v-btn icon>
                  <v-icon v-text="'mdi-emoticon-happy-outline'"></v-icon>
                </v-btn>
              </div>
            </template>
            <v-card
              class="emoji-picker pt-1 pa-3"
              slot="emoji-picker"
              slot-scope="{ emojis }"
            >
              <div>
                <div>
                  <div v-for="(emojiGroup, category) in emojis" :key="category">
                    <div class="bold-text font-size-h3">{{ category }}</div>
                    <div class="emojis">
                      <span
                        class="cursor-pointer"
                        v-for="(emoji, emojiName) in emojiGroup"
                        :key="emojiName"
                        @click="insert(emoji)"
                        :title="emojiName"
                        >{{ emoji }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </emoji-picker>
        </v-col>
        <v-col cols="9" md="11">
          <v-textarea
            v-model="message"
            placeholder="Type your message here..."
            rounded
            auto-grow
            v-bind="veeValidate('message', '')"
            outlined
            hide-details
            :key="resetKey"
            rows="1"
            row-height="15"
            dense
            append-icon="mdi-send"
            @click:append="sendFeedback"
            @keypress.enter.exact="sendFeedback"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { currentTimestamp } from "@/core/services/helper.service";
import {
  GET_APP_MESSAGES,
  DELETE_MESSAGE,
  EDIT_MESSAGE,
} from "@/store/apps/chat.module.js";
import veeValidate from "@/mixins/veeValidate.js";
import EmojiPicker from "vue-emoji-picker";
import JwtService from "@/core/services/jwt.service";
import io from "socket.io-client";
export const SOCKET_EVENTS = {
  RECEIVE_SELF_MESSAGE: "receive:self",
  RECEIVE_MESSAGE: "receive:message",
  REMOVE_MESSAGE: "remove:message",
  SEND_MESSAGE: "send:message",
  DELETE_MESSAGE: "delete:message",
};

let socket;
const connect = () => {
  socket = io(process.env.VUE_APP_SOCKET_URL2, {
    transports: ["websocket", "polling", "flashsocket"],
  });
  return new Promise((resolve) => {
    socket.on("connect", () => {
      resolve(socket);
    });
  });
};
const authenticateSocket = async (appId) => {
  return new Promise((resolve) => {
    socket
      .emit("authenticate", {
        token: JwtService.getToken(),
        app: appId,
        device_id: "",
      })
      .on("authenticated", () => {
        resolve({ authenticated: true });
      });
  });
};

export default {
  mixins: [veeValidate],
  components: { EmojiPicker },
  data() {
    return {
      fromShare: false,
      search: "",
      message: "",
      editContent: {},
      recentId: "",
      showEditForm: false,
      busy: false,
      resetKey: false,
      moreData: false,
      divScroll: "",
      latestData: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      getAppMessages: "getAppMessages",
      getTotalAppMessageCount: "getTotalAppMessageCount",
    }),
  },
  watch: {
    divScroll(val) {
      if (!val && this.getAppMessages.length < this.getTotalAppMessageCount) {
        this.getAllMessage(this.getAppMessages[0].id);
      }
    },
  },
  async created() {
    this.getAllMessage();
    await connect();
    await authenticateSocket(this.$route.params.app_id);
    this.receiveMessage();
    this.$nextTick(() => {
      this.scrollToBottom();
    });
  },
  beforeDestroy() {
    socket.disconnect();
  },
  methods: {
    ...mapMutations({
      setNewAppMessage: "setNewAppMessage",
      removeDeletedMessage: "removeDeletedMessage",
      updateMessage: "updateMessage",
    }),
    getScrollValue() {
      let element = document.getElementById("messageBox");
      this.divScroll = element ? element.scrollTop : 0;
    },
    getAllMessage(lastId) {
      if (lastId) {
        if (this.moreData) return;
        this.moreData = true;
      } else {
        if (this.loading) return;
        this.loading = true;
      }

      this.$store
        .dispatch(GET_APP_MESSAGES, {
          app_id: this.$route.params.app_id,
          lastID: lastId || "",
        })
        .then(() => {
          if (lastId) {
            this.$nextTick(() => {
              let messageBox = document.getElementById("messageBox");
              messageBox.scrollTop = 1000;
            });
          }
          this.moreData = false;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.moreData = false;
          this.notifyErrorMessage(err.message);
        });
    },
    async sendFeedback() {
      this.resetKey = false;
      if ((await this.validateAllFields()) && this.message.trim()) {
        const dataToPost = {
          created_at: currentTimestamp(),
          updated_at: currentTimestamp(),
          id: `${currentTimestamp()}${this.currentUser.id}`,
          message: this.message,
          status: 1,
          user: {
            id: this.currentUser.id,
            name: this.currentUser.name,
            image: this.currentUser.image,
          },
        };
        socket.emit(SOCKET_EVENTS.SEND_MESSAGE, dataToPost);
        this.latestData = dataToPost;
        if (this.message) {
          this.message = "";
          this.resetKey = true;
        }
      }
    },
    scrollToBottom() {
      let objDiv = document.getElementById("messageBox");
      objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
    },
    insert(emoji) {
      if (this.showEditForm) {
        this.editContent.message += emoji;
      } else {
        this.message += emoji;
      }
    },
    receiveMessage() {
      socket.on(SOCKET_EVENTS.RECEIVE_MESSAGE, (data) => {
        this.setNewAppMessage(data);
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      });
      socket.on(SOCKET_EVENTS.RECEIVE_SELF_MESSAGE, (data) => {
        this.latestData.id = data.id;
        this.setNewAppMessage(this.latestData);
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      });
    },
    deleteSocketMessage(id) {
      socket.emit(SOCKET_EVENTS.DELETE_MESSAGE, { id });
    },
    onEditClick(detail) {
      this.editContent = detail;
      this.showEditForm = true;
    },
    editMessage() {
      if (this.busy) return;
      this.busy = true;
      const dataToPost = {
        app_id: this.$route.params.app_id,
        chat_id: this.editContent.id,
        message: this.editContent.message,
      };
      this.$store
        .dispatch(EDIT_MESSAGE, dataToPost)
        .then((response) => {
          this.updateMessage(this.editContent);
          this.notifyUserMessage({ message: response.message });
          this.editContent = {};
          this.busy = false;
          this.showEditForm = false;
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    cancelEdit() {
      this.editContent = {};
    },
    onDeleteClick(detail) {
      if (this.busy) return;
      this.busy = false;
      this.$store
        .dispatch(DELETE_MESSAGE, {
          app_id: this.$route.params.app_id,
          chat_id: detail.id,
        })
        .then((response) => {
          this.deleteSocketMessage(detail.id);
          this.removeDeletedMessage(detail.id);
          this.notifyUserMessage(response.message);
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>

<style>
.scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: unset;
  height: 570px;
}
.lightGreyBackground {
  background-color: #f3f2f1;
}
.emoji-picker {
  position: absolute;
  z-index: 999;
  height: 300px;
  overflow-y: auto;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
</style>
